body {
  background-image: url('./enchantments.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.App {
  text-align: none;
}

.App-header {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding-top: 10vh;
  overflow: visible;
}

.front-page-card {
    width: 27vw;
    height: auto;
    color: white;
    border-radius: 5px;
    margin-top: 55px;
    background-color: rgba(12, 12, 12, 0.8);
    padding: 5px;
    overflow: hidden;
    transition: max-height 0.8s ease;
    text-align: justify;
    align-items: center;
    display: flex;
    flex-direction: column;
    transform: none;
    transition: all .3s ease;
}

.front-page-card:hover {
    background-color: rgba(12, 12, 12, 1);
    box-shadow: 0px 0.81em 0.62em rgba(0, 0, 0, 0.25);
}

.front-page-card p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.name {
  position: relative;
  margin-bottom: auto;
  background-color: transparent;
  text-align: center;
  left: 0;
  top: 0;
  margin-top: 10px;
  font-weight: 100;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.name::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: -38px;
  width: calc(100% + 76px);
  height: 1px;
  background-color: white;
}

.front-buttons {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}

.front-button-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 8px;
  height: 3.8em;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.front-button-1 .text {
  color: #343434;
  font-weight: 700;
  font-size: 1em;
  transition: 400ms;
}

.front-button-1 svg path {
  transition: 400ms;
  fill: #343434;
}

.front-button-1:hover {
  background-color: #343434;
}

.front-button-1:hover .text {
  color: #fff;
}

.front-button-1:hover svg path {
  fill: #fff;
}

.front-button-1 a, a:visited, a:hover, a:active {
  text-decoration: none;
}

.front-button-1 a:visited {
  color: inherit;
}

.git-a {
  text-decoration: none;
}

.front-button-2 {
  background-color: #fff;
  border: none;
  padding: 5px;
  position: relative;
  width: 7.3em;
  height: 3.8em;
  transition: 0.5s;
  border-radius: 0.4em;
  cursor: pointer;
}

.front-button-2 p {
  position: relative;
  font-size: 1em;
  font-weight: 700;
  right: 10px;
  margin: 0;
  padding: 0;
  transition: .5s;
  color: #0077b5;
}

.front-button-2 svg {
  position: absolute;
  top: 1.25em;
  right: 0.65em;
  margin: 0;
  padding: 0;
  opacity: 1;
  transition: 0.5s;
  fill: #0077b5;
}

.front-button-2:hover svg {
  fill: #fff;
}

.front-button-2:hover p {
  color: #fff;
}


.front-button-2:hover {
  background-color: #0077b5;
}

.front-button-2 a, a:visited, a:hover, a:active {
  text-decoration: none;
}

.front-button-2 a:visited {
  color: inherit;
}

.linked-a {
  cursor: pointer;
}

.email {
  font-size: 19px;
}