body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    position: relative;
}

.about-card {
    max-width: 31vw;
    height: auto;
    max-height: 90vh;
    color: white;
    border-radius: 5px;
    margin-top: 100px;
    background-color: rgba(12, 12, 12, 0.8);
    padding: 5px;
    overflow: hidden;
    transition: max-height 0.8s ease;
    text-align: justify;
    display: flex;
    flex-direction: column;
    transform: none;
    transition: all 0.3s ease;
    font-weight: 150;
  }

.about-card:hover {
    background-color: rgba(12, 12, 12, 1);
    box-shadow: 0px 0.81em 0.62em rgba(0, 0, 0, 0.25);
}

.about-card-title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 26px;
    font-size: 32px;
    font-weight: 300;
}

.about-additional-info {
    font-size: 19px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.about-me-text {
    padding-right: 10px;
    padding-left: 10px;
}

.image-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}   

.image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.image-container-grc {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}  

.images-grc {
    position: relative;
    left: 128px;
    width: 20vw;
    transition: all 0.3s ease;
    height: auto;
    max-height: 100%;
    padding-top: 37px;
    padding-bottom: 10px;
}

.images-grc:hover {
    transform: scale(1.05);
}

.images {
    width: 115px;
    height: auto;
    position: relative;
    top: 0;
    overflow: hidden;
    margin-bottom: 10px;
    flex-shrink: 0;
    margin-right: 10px;
    transition: all 0.3s ease;
}

#ps:hover {
    transform: scale(1.1);
}

#react:hover {
    transform: scale(1.1);
}

#api:hover {
    transform: scale(1.1);
}

#html:hover {
    transform: scale(1.1);
}

#css:hover {
    transform: scale(1.1);
}

#linux:hover {
    transform: scale(1.1);
}

.education-page {
    position: relative;
    width: 30vw;
    min-height: 375px;
    color: white;
    border-radius: 5px;
    margin-top: 100px;
    background-color: rgba(12, 12, 12, 0.8);
    padding: 10px;
    overflow: hidden;
    text-align: justify;
    transform: scale(1);
    transition: all 0.3s ease;
    font-weight: 150;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.education-page:hover {
    background-color: rgba(12, 12, 12, 1);
    box-shadow: 0px 0.81em 0.62em rgba(0, 0, 0, 0.25);
}

.education-info {
    font-size: 19px;
    margin-top: 5px;
    text-align: center;
}

.education-title {
    text-align: center;
    font-size: 32px;
    font-weight: 150;
    font-family: inherit;
}

.experience-page {
    width: 30vw;
    height: auto;
    color: white;
    border-radius: 5px;
    margin-top: 100px;
    background-color: rgba(12, 12, 12, 0.8);
    padding: 5px;
    overflow: hidden;
    transition: max-height 0.8s ease;
    text-align: center;
    display: flex;
    flex-direction: column;
    transform: none;
    transition: all 0.3s ease;
    font-weight: 150;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.experience-page:hover {
    background-color: rgba(12, 12, 12, 1);
    box-shadow: 0px 0.81em 0.62em rgba(0, 0, 0, 0.25);
}

.experience-title {
    text-align: center;
    font-size: 32px;
    font-weight: 250;
    font-family: inherit;
}

.experience-img {
    width: 25vw;
    height: auto;
    margin: 0 auto;
    transition: all 0.3s ease;
}

.experience-img:hover {
    transform: scale(1.05);
}

.experience-info {
    font-size: 24px;
    margin-top: 5px;
    text-align: center;
}

.experience-info2 {
    font-size: 18px;
    margin-top: -20px;
    margin-bottom: 5px;
    text-align: center;
}