.navigation-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: black;
    border-radius: 0.1em;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 5px;
}

.navigation-bar a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.1em;
    text-decoration: none;
}
  
.navigation-button {
    font-family: inherit;
    width: 7em;
    height: 3em;
    cursor: pointer;
    background: rgba(0, 0, 0, 1);
    border: none;
    border-radius: 0.5em;
    transition: all 0.3s;
    color: white;
    font-size: 0.9em;
    padding: 0 .1em;
}
  
.navigation-button:hover, .navigation-bar:focus {
    box-shadow: 0px 0.81em 0.62em rgba(0, 0, 0, 0.25);
    background: rgba(0, 128, 0, 1);
    transform: translate(0em, 0.37em);
}
  
.navigation-button:active {
    transform: scale(0.9);
}